import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import { FaArrowRight } from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Functions";

// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <Row
        className="AuthBackground"
        style={{ disply: "flex", justifyContent: "center" }}
      >
        <Col xs={24} md={10}>
          <div className="heroSection">
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col xs={20} md={20} className="formWrap">
                <Typography.Title
                  className="fontFamily1 smallTitle"
                  style={{ color: "#333d47" }}
                >
                  {<>Test Troubles? Anxiety, Lack of Confidence Holding You Back?</>}
                </Typography.Title>

                <Typography.Text
                  className="fontFamily1 smallText"
                  style={{ color: "#333d47" }}
                >
                  Are you feeling anxious or unsure before exams? Do nerves or self-doubt prevent you from showing what you truly know? Test anxiety and a lack of confidence can make even the most prepared students struggle. But it doesn’t have to stay that way—i12 Excellence Academy is here to help you build the skills and mindset you need to succeed
                </Typography.Text>
                <br />
                <br />
                <br />
                <Input
                  className="heroSerch"
                  placeholder="Find You trainer & consultant"
                  suffix={
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="heroButton"
                      style={{ borderRadius: "50px" }}
                    >
                      Search
                    </Button>
                  }
                  size="large"
                />
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={0} sm={0} md={12}>
          <div className="authImageBox">
            <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
              <Col xs={0} sm={12} md={24}>
                <div className="learningImage1" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              minHeight: "100vh",
              padding: "20px",
            }}
          >
            <Typography.Title
              className="fontFamily1"
              style={{
                fontSize: "35px",
                fontWeight: 600,
                color: "#333d47",
                textAlign: "center",
              }}
            >
              Test Troubles? Anxiety, Lack of Confidence Holding You Back?
            </Typography.Title>

            <Typography.Text
              className="fontFamily1"
              style={{ fontSize: "16px", color: "grey", textAlign: "center" }}
            >
              {
                <>
                  Are you feeling anxious or unsure before exams? Do nerves or
                  self-doubt prevent you from showing what you truly know? Test
                  anxiety and a lack of confidence can <br />
                  make even the most prepared students struggle. But it doesn’t
                  have to stay that way—i12 Excellence Academy is here to help
                  you build the skills and <br />
                  mindset you need to succeed
                  {/* We provide top-notch online training and consulting services
                  for <br /> K-12 and adult learners like you */}
                </>
              }
            </Typography.Text>
            <Row gutter={20} justify="center" style={{ margin: "50px 0" }}>
              <Col xs={22} sm={12} md={4}>
                <Card
                  className="coachCard"
                  bordered={false}
                  style={{
                    width: "100%",
                    height: "350px",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      width={40}
                      height={40}
                      // src="/images/search-icon.png"
                      src={ImageUrl("search-icon.png")}
                    />
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Title
                      className="fontFamily1"
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        color: "#333d47",
                        textAlign: "center",
                      }}
                    >
                      Get Certified:
                    </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",

                        color: "#333d47",
                        textAlign: "center",
                      }}
                    >
                      Follow these simple steps to start your learning journey.
                      From course selection to certification, we’ve made it easy
                      to achieve your goals
                    </Typography.Text>
                  </Row>
                </Card>
              </Col>
              <Col xs={22} sm={12} md={4}>
                <Card
                  className="coachCard"
                  bordered={false}
                  style={{
                    width: "100%",
                    height: "350px",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      width={40}
                      height={40}
                      // src="/images/calender.png"
                      src={ImageUrl("calender.png")}
                    />
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Title
                      className="fontFamily1"
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        color: "#333d47",
                        textAlign: "center",
                      }}
                    >
                      Find Course:
                    </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",

                        color: "#333d47",
                        textAlign: "center",
                      }}
                    >
                      Explore a wide range of tailored courses designed to help
                      you succeed. Find the perfect match for your learning
                      style and academic goals.
                    </Typography.Text>
                  </Row>
                </Card>
              </Col>
              <Col xs={22} sm={12} md={4}>
                <Card
                  className="coachCard"
                  bordered={false}
                  style={{
                    width: "100%",
                    height: "350px",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      width={40}
                      height={40}
                      // src="/images/certificate.png"
                      src={ImageUrl("certificate.png")}
                    />
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Title
                      className="fontFamily1"
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        color: "#333d47",
                        textAlign: "center",
                      }}
                    >
                      Book Your Seat:
                    </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",

                        color: "#333d47",
                        textAlign: "center",
                      }}
                    >
                      Secure your place in our upcoming sessions and take the
                      next step toward academic excellence. Booking your seat is
                      just a few clicks away!
                    </Typography.Text>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="heroButton"
                onClick={() => navigate("/courses")}
              >
                View Courses
              </Button>
            </Row>
          </div>
        </Col>
      </Row>

      {/* section 3 */}

      <Row style={{ backgroundColor: "#f2fbfa", justifyContent: "center" }}>
        <Col xs={24} md={20}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              // minHeight: "100vh",
              padding: "40px 20px",
            }}
          >
            <Row style={{ justifyContent: "center", alignItems: "center" }}>
              <Col xs={24} sm={24}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "35px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "center",
                    margin: "10px 0",
                  }}
                >
                  {
                    <>
                      Our Features
                      <br />
                    </>
                  }
                </Typography.Title>
              </Col>
              {/* <Col xs={24} sm={12}>
                <Typography.Text
                  className="fontFamily1"
                  style={{ fontSize: "16px", color: "grey", textAlign: "left" }}
                >
                  {
                    <>
                      We are not just here to help you pass exams- we want to
                      instill a <br /> genuine love for learning that lasts a
                      lifetime. We're all about <br /> building strong , long
                      lasting connetions between learners, turors <br /> and
                      coaches{" "}
                    </>
                  }
                </Typography.Text>
              </Col> */}
            </Row>
            <br />
            <br />
            <Row
              gutter={40}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Col xs={24} sm={24}>
                <Row gutter={[32, 32]} justify={"center"}>
                  {/* <Col
                    xs={24}
                    sm={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "60px",
                        height: "60px",
                      }}
                      icon={
                        <PiMedal
                          style={{ color: "#00a79d", fontSize: "25px" }}
                        />
                      }
                    />
                  </Col> */}
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    style={{ textAlign: "center" }}
                  >
                    <div className="future-cards">
                      <Button
                        type="primary"
                        shape="circle"
                        style={{
                          backgroundColor: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "60px",
                          height: "60px",
                          margin: "0 auto",
                        }}
                        icon={
                          <PiMedal
                            style={{ color: "#00a79d", fontSize: "25px" }}
                          />
                        }
                      />
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          margin: "10px 0",
                        }}
                      >
                        {
                          <>
                            Explore Education Through Exceptional Tutoring at
                            i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            Unlock a world of opportunities with personalized
                            tutoring at i12 Excellence Academy, designed to
                            foster growth and development. Our expert tutors
                            provide dedicated support, guiding students on a
                            journey of discovery and skill-building. With
                            tailored instruction, students gain valuable
                            knowledge and essential skills, setting the stage
                            for personal and professional advancement in a
                            dynamic and ever-changing world.
                          </>
                        }
                      </Typography.Text>
                      <Button
                        style={{
                          marginTop: "10px",

                          cursor: "pointer",
                          width: "auto",
                          height: "auto",
                          borderWidth: "2px",
                          marginBottom: 0,
                        }}
                        onClick={() => navigate("/ourFeatures")}
                        size="large"
                        className="heroButton"
                      >
                        Read More
                      </Button>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    style={{ textAlign: "center" }}
                  >
                    <div className="future-cards">
                      <Button
                        type="primary"
                        shape="circle"
                        style={{
                          backgroundColor: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "60px",
                          height: "60px",
                          margin: "0 auto",
                        }}
                        icon={
                          <TbBolt
                            style={{ color: "#00a79d", fontSize: "25px" }}
                          />
                        }
                      />
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          margin: "10px 0",
                        }}
                      >
                        {
                          <>
                            Affordable Tutoring for Accessible Education at i12
                            Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            Accessing affordable tutoring at i12 Excellence
                            Academy makes quality education accessible to
                            everyone, regardless of financial constraints. By
                            offering personalized support at reasonable rates,
                            we prioritize inclusivity and ensure that
                            individuals from diverse backgrounds can pursue
                            their academic goals. Our commitment is to provide
                            valuable educational opportunities that empower all
                            students to achieve their full potential.
                          </>
                        }
                      </Typography.Text>
                      <Button
                        style={{
                          marginTop: "10px",

                          cursor: "pointer",
                          width: "auto",
                          height: "auto",
                          borderWidth: "2px",
                          marginBottom: 0,
                        }}
                        onClick={() => navigate("/ourFeatures")}
                        size="large"
                        className="heroButton"
                      >
                        Read More
                      </Button>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    style={{ textAlign: "center" }}
                  >
                    <div className="future-cards">
                      <Button
                        type="primary"
                        shape="circle"
                        style={{
                          backgroundColor: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "60px",
                          height: "60px",
                          margin: "0 auto",
                        }}
                        icon={
                          <LiaBrainSolid
                            style={{ color: "#00a79d", fontSize: "30px" }}
                          />
                        }
                      />
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          margin: "10px 0",
                        }}
                      >
                        {
                          <>
                            Individual and Small Group Tutoring at i12
                            Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                              Personalized Learning for Maximum Impact
                            </strong>{" "}
                            <br />
                            <br />
                            Experience the benefits of individualized attention
                            and tailored instruction with our one-on-one and
                            small group tutoring sessions at i12 Excellence
                            Academy. Whether you prefer focused, personalized
                            support or collaborative learning with peers, our
                            expert tutors provide customized guidance to meet
                            your unique needs. Enhance understanding, build
                            confidence, and achieve academic success with a
                            learning experience designed for your success.
                          </>
                        }
                      </Typography.Text>
                      <Button
                        style={{
                          marginTop: "10px",

                          cursor: "pointer",
                          width: "auto",
                          height: "auto",
                          borderWidth: "2px",
                          marginBottom: 0,
                        }}
                        onClick={() => navigate("/ourFeatures")}
                        size="large"
                        className="heroButton"
                      >
                        Read More
                      </Button>
                    </div>
                  </Col>
                </Row>
                {/* <br />
                <br />
                <Row>
                  <Col
                    xs={24}
                    sm={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "60px",
                        height: "60px",
                      }}
                      icon={
                        <TbBolt
                          style={{ color: "#00a79d", fontSize: "25px" }}
                        />
                      }
                    />
                  </Col>
                  <Col xs={24} sm={20}>
                    <Typography.Title
                      className="fontFamily1"
                      style={{
                        fontSize: "25px",
                        fontWeight: 600,
                        color: "black",
                        textAlign: "left",
                        margin: "10px 0",
                      }}
                    >
                      {
                        <>
                          Affordable Tutoring for Accessible Education at i12
                          Excellence Academy
                        </>
                      }
                    </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "16px",
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      {
                        <>
                          Accessing affordable tutoring at i12 Excellence
                          Academy makes quality education accessible to
                          everyone, regardless of financial constraints. By
                          offering personalized support at reasonable rates, we
                          prioritize inclusivity and ensure that individuals
                          from diverse backgrounds can pursue their academic
                          goals. Our commitment is to provide valuable
                          educational opportunities that empower all students to
                          achieve their full potential.
                        </>
                      }
                    </Typography.Text>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col
                    xs={24}
                    sm={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "60px",
                        height: "60px",
                      }}
                      icon={
                        <LiaBrainSolid
                          style={{ color: "#00a79d", fontSize: "30px" }}
                        />
                      }
                    />
                  </Col>
                  <Col xs={24} sm={20}>
                    <Typography.Title
                      className="fontFamily1"
                      style={{
                        fontSize: "25px",
                        fontWeight: 600,
                        color: "black",
                        textAlign: "left",
                        margin: "10px 0",
                      }}
                    >
                      {
                        <>
                          Individual and Small Group Tutoring at i12 Excellence
                          Academy
                        </>
                      }
                    </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "16px",
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      {
                        <>
                         <strong>Personalized Learning for Maximum Impact</strong> <br/> Experience
                          the benefits of individualized attention and tailored
                          instruction with our one-on-one and small group
                          tutoring sessions at i12 Excellence Academy. Whether
                          you prefer focused, personalized support or
                          collaborative learning with peers, our expert tutors
                          provide customized guidance to meet your unique needs.
                          Enhance understanding, build confidence, and achieve
                          academic success with a learning experience designed
                          for your success.
                        </>
                      }
                    </Typography.Text>
                  </Col>
                </Row> */}
              </Col>
              {/* <Col
                xs={24}
                sm={12}
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="videoBox"
                  style={{
                    width: "100%",
                    height: "350px",
                    borderRadius: "50px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <video autoPlay={true} muted loop className="homeVideo">
                    <source
                      // src={"/images/vdo.mp4"}
                      // src={ImageUrl("vdo.mp4")}
                      src={`${UPLOADS_URL}/vdo.mov`}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div
                  className="vdo"
                  style={{
                    position: "absolute",
                    height: "100px",
                    top: "-50px",
                    backgroundColor: "#00a79d",
                    borderRadius: "20px",
                    display: "flex",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Row>
                    <Col>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1 smallTitle"
                          style={{
                            // fontSize: "40px",
                            fontWeight: "normal",
                            color: "white",
                            textAlign: "left",
                            margin: "10px 0",
                          }}
                        >
                          10
                        </Typography.Title>
                        &emsp;
                        <Typography.Text
                          className="fontFamily1 smallText"
                          style={{
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "white",
                            textAlign: "left",
                          }}
                        >
                          {
                            <>
                              YEARS <br /> EXPERIANCE
                            </>
                          }
                        </Typography.Text>
                        &emsp;
                        <Typography.Text
                          className="fontFamily1 smallTitle"
                          style={{
                            // fontSize: "40px",
                            fontWeight: "normal",
                            color: "white",
                            textAlign: "left",
                            margin: "10px 0",
                          }}
                        >
                          |
                        </Typography.Text>
                        &emsp;
                        <Typography.Title
                          className="fontFamily1 smallText"
                          style={{
                            fontWeight: "normal",
                            color: "white",
                            textAlign: "left",
                            margin: "10px 0",
                          }}
                        >
                          250
                        </Typography.Title>
                        &emsp;
                        <Typography.Text
                          className="fontFamily1"
                          style={{
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "white",
                            textAlign: "left",
                          }}
                        >
                          {
                            <>
                              TYPES OF <br /> COURSES
                            </>
                          }
                        </Typography.Text>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col> */}
            </Row>
          </div>
        </Col>
      </Row>

      {/* section 4 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              minHeight: "100vh",
              padding: "20px",
            }}
          >
            <Row gutter={30} justify="center" style={{ margin: "50px 0" }}>
              <Col xs={22} sm={10}>
                <Card
                  className="joinCard1"
                  bordered={false}
                  style={{
                    width: "350px",
                    maxWidth: "100%",
                    height: "300px",
                    borderRadius: "10px",
                    display: "flex",
                    padding: "20px",
                    flexDirection: "column",
                  }}
                />
                <br />
                <Card
                  className="joinCard2"
                  bordered={false}
                  style={{
                    width: "100%",
                    height: "400px",
                    borderRadius: "10px",
                    display: "flex",
                    padding: "20px",
                    flexDirection: "column",
                  }}
                />
              </Col>
              <Col xs={22} sm={10}>
                <Card
                  bordered={false}
                  className="joinCard3"
                  style={{
                    width: "100%",
                    background: "#f2fbfa",
                    height: "500px",
                    borderRadius: "10px",
                    display: "flex",
                    padding: "20px",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Title
                      className="fontFamily1 smallTitle2"
                      style={{
                        fontWeight: 600,
                        color: "#333d47",
                        textAlign: "left",
                      }}
                    >
                      {<>Get Certified:</>}
                    </Typography.Title>
                    <Typography.Text
                      className="fontFamily1 smallText"
                      style={{
                        color: "#333d47",
                        textAlign: "left",
                        fontSize: "16px",
                      }}
                    >
                      {
                        <>
                          Complete your course and receive a certification that
                          highlights your dedication and success. Boost your
                          confidence and showcase your achievements!
                          <br />
                          Create a mini about us section below the content with
                          this content and a read more button that redirects to
                          the about us page:
                          <br />
                          <br />
                          Do you find yourself overwhelmed by exams, falling
                          behind on assignments, or frustrated with low grades?
                          Whether it’s a challenge understanding the material,
                          managing your time, or staying organized, academic
                          struggles can be stressful and discouraging. Don’t
                          worry—you’re not alone, and there are solutions to
                          help you get back on track. i12 Excellence Academy is
                          here to guide you through these challenges and help
                          you reach your full potential!
                          {/* Step into a world full of opportunities where <br />{" "}
                          we always support and celebrate <br /> your growth and
                          achievements. */}
                        </>
                      }
                    </Typography.Text>
                  </Row>
                  <br />
                  <Row>
                    <Button
                      style={{
                        marginTop: "10px",

                        cursor: "pointer",
                        width: "auto",
                        height: "auto",
                        borderWidth: "2px",
                        marginBottom: 0,
                      }}
                      onClick={() => navigate("/about-us")}
                      size="large"
                      className="heroButton"
                    >
                      Read More
                    </Button>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Homepage;
