import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Upload,
  Card,
  Select,
  InputNumber,
  TimePicker,
  DatePicker,
  Skeleton,
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { UPLOADS_URL, ASSESMEN, ASSESMENT,ADMIN } from "../../config/constants/api";
import { CONTENT_TYPE } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { InboxOutlined } from '@ant-design/icons';

import {
    FaLongArrowAltLeft
} from "react-icons/fa";
const {TextArea} = Input;
const { Option } = Select;

function AssesmentDetails() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [assesment, setAssesment] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false)
  const [imageNew, setImageNew] = useState();


  useEffect(() => {
    getAssesment();
  }, []);

  const getAssesment = async () => {
    setLoading(true);
    const res = await Get(
      `${ASSESMENT.getAssesmentById}${id}`,
      token
    );

    console.log("<<<<",assesment)

    setAssesment(res.data.assesment);
    setLoading(false);
  };


  const handleStatus = async () => {
    try {
      const response = await Get(
        ASSESMENT.toggleStatus + "/" + assesment._id,
        token,
        {}
      );
      const newUser = { ...assesment };

      newUser.isActive = !assesment.isActive;
      setModalOpen(false);
      setAssesment(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {

    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }


    for (const key in values) {
      if (key !== "image") {
        const item = values[key];
        formObject.append(key, item);
      }
    }

    Post(ASSESMENT.updateAssesment+id, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        if (response?.data?.status) {
          console.log(response?.data);
  
          swal("Success!", "Assesment Updated Successfully", "success");
          getAssesment()

          setEditMode(false);
          setImageNew();
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const deleteAssesment =async () =>{
    try {
      const response = await Get(ASSESMENT.deleteAssesment + id, token);
      setLoading(false);
      console.log("response22", response);
      if (response?.status) {
     swal("Success","Assesment Deleted Successfully",'success')
     navigate(-1)
      } else {
     
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }



  return (
    <Layout style={{ minHeight: "100vh" }}>
    <Row
      className="blueBackground"
      style={{
        height: "45vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
        <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "black" }}
        >
              {editMode ? "Edit Assesment Details" : "View Assesment Details"}
          
        </Typography.Title>

            <Button style={{width:"40px",height:"40px",background:"#7cc059", display:'flex',justifyContent:'center',alignItems:'center', color:"white", position:"absolute", left:150,cursor:"pointer", marginTop:20,padding:10, border:'none', borderRadius:'100px'}} onClick={()=> navigate(-1)}>
          
        <FaLongArrowAltLeft style={{fontSize:"30px" , color:"white"}} />
        </Button>
      </Row>
    </Row>

    {/* section 2 */}
    <Row
      className="whiteBackground"
      style={{ backgroundColor: "white", justifyContent: "center" }}
    >
      <Col xs={24} md={22}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "100px 20px",
          }}
        >
          <Card
            className="infoBox"
            bordered={false}
            style={{
              width: "100%",
              padding: "20px",
            }}
          >
          
          {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Skeleton active  paragraph={{ rows:10 }} />
          </div>
        )
            }

            {!loading && assesment && (<>


              <Form
          layout="vertical"
          name="basic"
          className="contactForm"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row style={{ padding: "20px" }}>
            <Col xs={24} md={18}>
          
              {editMode ? (
                <>
                       <Row>
              <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        File :
                      </Typography.Title>
                      </Row>
                      <Row>
                  <Form.Item name="image">
               

                    <Upload
                      name="image"
                      showUploadList={true}
                      style={{ position: "relative" }}
                      beforeUpload={(file) => {
                        setImageNew(URL.createObjectURL(file));
                        return false;
                      }}
                    >

                      {/* {imageNew ? <Image
preview={false}
                  src={imageNew
                    ? imageNew
                    : !assesment?.file
                    ? "/images/avatar.png"
                    : UPLOADS_URL + "/" + assesment?.file }
                  height={300}
                  width={500}
                  style={{ borderRadius: "10px", objectFit: "cover" }}
                /> : <div style={{height:300,width:500, padding:20, display:'flex', textAlign:"center", flexDirection:"column",justifyContent:"center", alignItems:"center", backgroundColor:"#fafafa", border:"2px dashed #dadada", borderRadius:'10px'}}> <p className="ant-upload-drag-icon">
                <InboxOutlined style={{fontSize:"30px"}} />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload only. Strictly prohibited from uploading company data or other
                banned files.
              </p></div>} */}

              <div style={{height:300,width:500, padding:20, display:'flex', textAlign:"center", flexDirection:"column",justifyContent:"center", alignItems:"center", backgroundColor:"#fafafa", border:"2px dashed #dadada", borderRadius:'10px'}}> <p className="ant-upload-drag-icon">
                <InboxOutlined style={{fontSize:"30px"}} />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload only. Strictly prohibited from uploading company data or other
                banned files.
              </p></div>


                    </Upload>
                  </Form.Item>
              
              </Row>

                 <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Title :
                      </Typography.Title>
                   
                      <Form.Item
                        name="title"
                        initialValue={assesment?.title}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input assesment title",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Title"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Type :
                      </Typography.Title>
                   
                      <Form.Item
                        name="charges"
                        initialValue={assesment?.type}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Price",
                          },
                        ]}
                      >
                        <Select
           placeholder="Select Category"
           className="formSelectBox"
           options={[
            { value: "QUIZ", label: "Quiz" },
            { value: "ASSIGNMENT", label: "Assignment" },
          ]}/>
        
                      </Form.Item>
                    </Col>
                  </Row>

               

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                       Due Date :
                      </Typography.Title>
                   
                      <Form.Item
                        name="assesmentDate"
                        initialValue={dayjs(assesment?.dueDate)}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select assesment Date",
                          },
                        ]}
                      >
                        <DatePicker  style={{width:"100%"}} size="large"
                          placeholder="Enter Assesment Date"
                          className="signupFormInput"/>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                       Due Time :
                      </Typography.Title>
                   
                      <Form.Item
                        name="assesmentTime"
                        initialValue={dayjs(assesment?.dueTime)}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select assesment Time",
                          },
                        ]}
                      >
                        <TimePicker  style={{width:"100%"}} size="large"
                          placeholder="Enter assesment Time"
                          className="signupFormInput"/>
                      </Form.Item>
                    </Col>
                  </Row>


                  <Row gutter={20}>
                  <Col
                      xs={24}
                      
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                       Description:
                      </Typography.Title>
                   
                      <Form.Item
                        name="description"
                        initialValue={assesment?.description}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Assesment description",
                          },
                        ]}
                      >
                        <TextArea
                        rows={5}
                          size="large"
                          placeholder="Enter Description"
                          className="signupFormInput2"
                        />
                      </Form.Item>
                    </Col>
                 
                  </Row>

                 
              

                
                </>
              ) : (
                <>
                     <Row style={{ padding: "10px" }}>
            <Col xs={24} md={12}>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Assesment Title{" "}
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {assesment?.title}
                </h5>
              </Col>
              <Col xs={24} md={12}>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Type{" "}
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {assesment?.type}
                </h5>
              </Col>
              
            </Row>

            <br/>
          
            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Due Date{" "}
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {dayjs(assesment?.dueDate).format("MM/DD/YYYY")}
                  
                </h5>
              </Col>
              <Col xs={24} md={12}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Due Time
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {dayjs(assesment?.dueTime).format("HH:MM  A")}
                </h5>
              </Col>
            
            </Row>
            <br/>
            <Row style={{ padding: "10px" }}>

            <Col xs={24} md={12}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Assesment Description{" "}
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {assesment?.description}
                </h5>
              </Col>
   

        

            </Row>
            <br/>

         
                </>
              )}

              <Row style={{ marginTop: 30 }}>
                {editMode && (
                  <>
                    {" "}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                    >
                      Save Changes
                    </Button>
                    &emsp;
                    <Button
                      className="fontFamily1"
                      style={{
                        marginTop: "0px",
                        padding: "10px 30px",
                        cursor: "pointer",
                        color: "black",
                        borderRadius: "5px",
                        height: "auto",
                        border: "1px solid #203657",
                        fontWeight: "bold",
                      }}
                      ghost
                      size="large"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}

                
                {!editMode && (
                  <Row justify={"center"}>
                    <Col style={{ textAlign: "center" }}>
                    <Button
                        type="primary"
                        htmlType="button"
                        className="loginButton"
                        onClick={() => setEditMode(true)}
                      >
                        Edit Assesment
                      </Button> &emsp;
                      <Button
                        type="primary"
                        htmlType="button"
                        className="deleteButton"
                        onClick={() => deleteAssesment(true)}
                      >
                        Delete Assesment
                      </Button> &emsp;<Button
                         className="fontFamily1"
                         style={{
                           marginTop: "0px",
                           padding: "10px 30px",
                           cursor: "pointer",
                           color: "black",
                           borderRadius: "5px",
                           height: "auto",
                           border: "1px solid #203657",
                           fontWeight: "bold",
                         }}
                         ghost
                         size="large"
                        onClick={() => window.open(UPLOADS_URL + "/" + assesment.file)}
                      >
                        Download File
                      </Button>
                    </Col>
                  </Row>
                )}





              </Row>
            </Col>
          </Row>
        </Form>

        
            </>)}
   
 

            
           
          </Card>
        </div>
      </Col>
    </Row>

    
   
  </Layout>
//     <Layout className="configuration">
//       <div className="boxDetails2">
//         <Row style={{ padding: "10px 20px" }}>
//           <Col
//             xs={24}
//             md={12}
//             style={{ display: "flex", alignItems: "center" }}
//           >
//             <FaArrowLeft
//               style={{ fontWeight: "bold", fontSize: "20px" }}
//               onClick={() => navigate(-1)}
//             />
//             &emsp;
//             <h1 className="pageTitle" style={{ margin: 0 }}>
//               {editMode ? "Edit Assesment Details" : "View Assesment Details"}
//             </h1>
//           </Col>
         
//         </Row>
//         <br />

//         {loading && (
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "50vh",
//             }}
//           >
//             <Skeleton active  paragraph={{ rows:10 }} />
//           </div>
//         )
//             }

//             {!loading && assesment && (<>


//               <Form
//           layout="vertical"
//           name="basic"
//           className="contactForm"
//           labelCol={{
//             span: 0,
//           }}
//           wrapperCol={{
//             span: 24,
//           }}
//           initialValues={{
//             remember: true,
//           }}
//           onFinish={onFinish}
//           onFinishFailed={onFinishFailed}
//           autoComplete="off"
//         >
//           <Row style={{ padding: "20px" }}>
//             <Col xs={24} md={18}>
//               <Row>
//                 {editMode ? (
//                   <Form.Item name="image">
//                     <Upload
//                       name="image"
//                       showUploadList={false}
//                       style={{ position: "relative" }}
//                       beforeUpload={(file) => {
//                         setImageNew(URL.createObjectURL(file));
//                         return false;
//                       }}
//                     >
//                       {" "}
//                       <div
//                         style={{
//                           padding: "8px",
//                           position: "absolute",
//                           right: -10,
//                           zIndex: 2,
//                           bottom: 40,
//                           backgroundColor: "#7CC059",
//                           display: "flex",
//                           maxWidth: "fit-content",
//                           color: "white",
//                           borderRadius: "20px",
//                         }}
//                       >
//                         <TbCameraPlus />
//                       </div>{" "}

// <Image
// preview={false}
//                   src={imageNew
//                     ? imageNew
//                     : !assesment?.image
//                     ? "/images/avatar.png"
//                     : UPLOADS_URL + "/" + assesment?.image }
//                   height={300}
//                   width={500}
//                   style={{ borderRadius: "10px", objectFit: "cover" }}
//                 />
//                     </Upload>
//                   </Form.Item>
//                 ) : (
//                   <Image
//                   src={!assesment.image ? "/images/avatar.png" : UPLOADS_URL + "/" + assesment.image }
//                   height={300}
//                   width={500}
//                   style={{ borderRadius: "10px", objectFit: "cover" }}
//                 />
//                 )}
//               </Row>

//               <br />
//               <br />

//               {editMode ? (
//                 <>
//                   <Row gutter={20}>
//                     <Col
//                       xs={24}
//                       sm={12}
//                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
//                     >
//                       <Typography.Title
//                         className="fontFamily1"
//                         style={{
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           color: "black",
//                           textAlign: "left",
//                           marginBottom: 20,
//                         }}
//                       >
//                         Assesment Code :
//                       </Typography.Title>
                   
//                       <Form.Item
//                         name="assesmentCode"
//                         initialValue={assesment?.assesmentCode}
//                         style={{ width: "100%" }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please input your Assesment Code",
//                           },
//                         ]}
//                       >
//                         <Input
//                           size="large"
//                           placeholder="Enter AssesmentCode"
//                           className="signupFormInput"
//                         />
//                       </Form.Item>
//                     </Col>
//                     <Col
//                       xs={24}
//                       sm={12}
//                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
//                     >
//                       <Typography.Title
//                         className="fontFamily1"
//                         style={{
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           color: "black",
//                           textAlign: "left",
//                           marginBottom: 20,
//                         }}
//                       >
//                         Title :
//                       </Typography.Title>
                   
//                       <Form.Item
//                         name="title"
//                         initialValue={assesment?.title}
//                         style={{ width: "100%" }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please input assesment title",
//                           },
//                         ]}
//                       >
//                         <Input
//                           size="large"
//                           placeholder="Enter Title"
//                           className="signupFormInput"
//                         />
//                       </Form.Item>
//                     </Col>
//                   </Row>

//                   <Row gutter={20}>
//                     <Col
//                       xs={24}
//                       sm={12}
//                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
//                     >
//                       <Typography.Title
//                         className="fontFamily1"
//                         style={{
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           color: "black",
//                           textAlign: "left",
//                           marginBottom: 20,
//                         }}
//                       >
//                        Description:
//                       </Typography.Title>
                   
//                       <Form.Item
//                         name="description"
//                         initialValue={assesment?.description}
//                         style={{ width: "100%" }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please input Assesment description",
//                           },
//                         ]}
//                       >
//                         <Input
//                           size="large"
//                           placeholder="Enter Description"
//                           className="signupFormInput"
//                         />
//                       </Form.Item>
//                     </Col>
//                     <Col
//                       xs={24}
//                       sm={12}
//                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
//                     >
//                       <Typography.Title
//                         className="fontFamily1"
//                         style={{
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           color: "black",
//                           textAlign: "left",
//                           marginBottom: 20,
//                         }}
//                       >
//                         Category :
//                       </Typography.Title>
                   
//                       <Form.Item
//                         name="category"
//                         initialValue={assesment?.category._id}
//                         style={{ width: "100%" }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please input assesment category",
//                           },
//                         ]}
//                       >
//                          <Select
//            placeholder="Select Category"
//            className="signupSelectBox"
//         >
//           {categories?.map((item,index) => {
//             return(<Option value={item._id}>{item.title}</Option>)
//           })}

//         </Select>
//                       </Form.Item>
//                     </Col>
//                   </Row>

//                   <Row gutter={20}>
//                     <Col
//                       xs={24}
//                       sm={12}
//                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
//                     >
//                       <Typography.Title
//                         className="fontFamily1"
//                         style={{
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           color: "black",
//                           textAlign: "left",
//                           marginBottom: 20,
//                         }}
//                       >
//                        Start Date :
//                       </Typography.Title>
                   
//                       <Form.Item
//                         name="startDate"
//                         initialValue={dayjs(assesment?.startDate)}
//                         style={{ width: "100%" }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please input start Date",
//                           },
//                         ]}
//                       >
//                         <DatePicker  style={{width:"100%"}} size="large"
//                           placeholder="Enter start Date"
//                           className="signupFormInput"/>
//                       </Form.Item>
//                     </Col>
//                     <Col
//                       xs={24}
//                       sm={12}
//                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
//                     >
//                       <Typography.Title
//                         className="fontFamily1"
//                         style={{
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           color: "black",
//                           textAlign: "left",
//                           marginBottom: 20,
//                         }}
//                       >
//                         Duration :
//                       </Typography.Title>
                   
//                       <Form.Item
//                         name="duration"
//                         initialValue={assesment?.duration}
//                         style={{ width: "100%" }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please input assesment duration",
//                           },
//                         ]}
//                       >
//                         <Input
//                           size="large"
//                           placeholder="Enter Duration"
//                           className="signupFormInput"
//                         />
//                       </Form.Item>
//                     </Col>
//                   </Row>

//                   <Row gutter={20}>
//                   <Col
//                       xs={24}
//                       sm={12}
//                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
//                     >
//                       <Typography.Title
//                         className="fontFamily1"
//                         style={{
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           color: "black",
//                           textAlign: "left",
//                           marginBottom: 20,
//                         }}
//                       >
//                         Price :
//                       </Typography.Title>
                   
//                       <Form.Item
//                         name="price"
//                         initialValue={assesment?.price}
//                         style={{ width: "100%" }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please input price",
//                           },
//                         ]}
//                       >
//                         <Input
//                         prefix="$"
//                           size="large"
//                           placeholder="Enter Price"
//                           className="signupFormInput"
//                         />
//                       </Form.Item>
//                     </Col>
//                     <Col
//                       xs={24}
//                       sm={12}
//                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
//                     >
//                       <Typography.Title
//                         className="fontFamily1"
//                         style={{
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           color: "black",
//                           textAlign: "left",
//                           marginBottom: 20,
//                         }}
//                       >
//                         Features :
//                       </Typography.Title>
                   
//                       <Form.Item
//                         name="features"
//                         initialValue={assesment?.features}
//                         style={{ width: "100%" }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please input Features",
//                           },
//                         ]}
//                       >
//                         <Input
//                           size="large"
//                           placeholder="Enter Features"
//                           className="signupFormInput"
//                         />
//                       </Form.Item>
//                     </Col>
                 
//                   </Row>
         
                
                 
              

                
//                 </>
//               ) : (
//                 <>
//                      <Row style={{ padding: "10px" }}>
//             <Col xs={24} md={12}>
//                 <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     fontWeight: "bold",
//                     marginBottom:10
//                   }}
//                 >
//                   Assesment Code{" "}
//                 </h5>
//                 <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     color: "#7a7e7f",
//                     fontWeight: "normal",
//                   }}
//                 >
//                   {assesment?.assesmentCode}
//                 </h5>
//               </Col>
//               <Col xs={24} md={12}>
//                 <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     fontWeight: "bold",
//                     marginBottom:10
//                   }}
//                 >
//                   Title{" "}
//                 </h5>
//                 <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     color: "#7a7e7f",
//                     fontWeight: "normal",
//                   }}
//                 >
//                   {assesment?.title}
//                 </h5>
//               </Col>
              
//             </Row>

//             <br/>
//             <Row style={{ padding: "10px" }}>
//             <Col xs={24} md={12}>
//               <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     fontWeight: "bold",
//                     marginBottom:10
//                   }}
//                 >
//                   Description{" "}
//                 </h5>
//                 <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     color: "#7a7e7f",
//                     fontWeight: "normal",
//                   }}
//                 >
//                   {assesment?.description}
//                 </h5>
//               </Col>
//               <Col xs={24} md={12}>
//               <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     fontWeight: "bold",
//                     marginBottom:10
//                   }}
//                 >
//                   Category
//                 </h5>
//                 <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     color: "#7a7e7f",
//                     fontWeight: "normal",
//                   }}
//                 >
//                   {assesment?.category.title}
//                 </h5>
//               </Col>
//             </Row>
//             <br/>

//             <Row style={{ padding: "10px" }}>
//               <Col xs={24} md={12}>
//               <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     fontWeight: "bold",
//                     marginBottom:10
//                   }}
//                 >
//                   Start Date{" "}
//                 </h5>
//                 <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     color: "#7a7e7f",
//                     fontWeight: "normal",
//                   }}
//                 >
//                   {dayjs(assesment?.startDate).format("MM/DD/YYYY")}
                  
//                 </h5>
//               </Col>
//               <Col xs={24} md={12}>
//               <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     fontWeight: "bold",
//                     marginBottom:10
//                   }}
//                 >
//                   Duration{" "}
//                 </h5>
//                 <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     color: "#7a7e7f",
//                     fontWeight: "normal",
//                   }}
//                 >
//                   {assesment?.duration}
//                 </h5>
//               </Col>
            
//             </Row>
//             <br/>
//             <Row style={{ padding: "10px" }}>
//             <Col xs={24} md={12}>
//               <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     fontWeight: "bold",
//                     marginBottom:10
//                   }}
//                 >
//                   Price{" "}
//                 </h5>
// <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     color: "#7a7e7f",
//                     fontWeight: "normal",
//                     margin:"10px 0px"
//                   }}
//                 >
//                  $ {assesment.price}
                
//                 </h5>
               
//               </Col>
//               <Col xs={24} md={12}>
//               <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     fontWeight: "bold",
//                     marginBottom:10
//                   }}
//                 >
//                   Features{" "}
//                 </h5>
//                 {assesment?.features?.length > 0 && assesment?.features.toString().split(",")?.map(item => { return( <h5
//                   style={{
//                     display: "block",
//                     fontSize: 16,
//                     color: "#7a7e7f",
//                     fontWeight: "normal",
//                     margin:"10px 0px"
//                   }}
//                 >
//                   {item}
                  
//                 </h5>)})}
               
//               </Col>

//             </Row>
//                 </>
//               )}

//               <Row style={{ marginTop: 30 }}>
//                 {editMode && (
//                   <>
//                     {" "}
//                     <Button
//                       type="primary"
//                       htmlType="submit"
//                       className="loginButton"
//                     >
//                       Save Changes
//                     </Button>
//                     &emsp;
//                     <Button
//                       className="fontFamily1"
//                       style={{
//                         marginTop: "0px",
//                         padding: "10px 30px",
//                         cursor: "pointer",
//                         color: "black",
//                         borderRadius: "5px",
//                         height: "auto",
//                         border: "1px solid #203657",
//                         fontWeight: "bold",
//                       }}
//                       ghost
//                       size="large"
//                       onClick={(e) => {
//                         e.preventDefault();
//                         setEditMode(false);
//                       }}
//                     >
//                       Cancel
//                     </Button>
//                   </>
//                 )}
//                 {!editMode && (
//                   <Row justify={"center"}>
//                     <Col style={{ textAlign: "center" }}>
//                       <Button
//                         type="primary"
//                         htmlType="button"
//                         className="loginButton"
//                         onClick={() => setEditMode(true)}
//                       >
//                         Edit Assesment
//                       </Button>
//                     </Col>
//                   </Row>
//                 )}
//               </Row>
//             </Col>
//           </Row>
//         </Form>

        
//             </>)}


        
//         <br />
//         <br />
//       </div>

//       <Modal
//         open={modalOpen}
//         onOk={() => handleStatus()}
//         onCancel={() => setModalOpen(false)}
//         okText="Yes"
//         className="StyledModal"
//         style={{
//           left: 0,
//           right: 0,
//           marginLeft: "auto",
//           marginRight: "auto",
//           textAlign: "center",
//         }}
//         cancelText="No"
//         cancelButtonProps={{
//           style: {
//             border: "2px solid #203453",
//             color: "#203453",
//             height: "auto",
//             padding: "6px 35px",
//             borderRadius: "50px",
//             fontSize: "16px",
//             marginTop: "15px",
//           },
//         }}
//         okButtonProps={{
//           style: {
//             backgroundColor: "#203453",
//             color: "white",
//             marginTop: "15px",
//             height: "auto",
//             padding: "5px 35px",
//             borderRadius: "50px",
//             fontSize: "16px",
//             border: "2px solid #203453",
//           },
//         }}
//       >
//         <Image
//           src="../images/question.png"
//           preview={false}
//           width={100}
//           height={120}
//         />
//         <Typography.Title level={4} style={{ fontSize: "25px" }}>
//           {assesment?.isActive ? "Deactivate" : "Activate"}
//         </Typography.Title>
//         <Typography.Text style={{ fontSize: 16 }}>
//         Do You Want To  {assesment?.isActive ? "Deactivate" : "Activate"} This User?
//         </Typography.Text>
//       </Modal>


//       <br />
//       <br />
//     </Layout>
  );
}
export default AssesmentDetails;
