import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Button,
  Row,
  Avatar,
  Typography,
  Layout,
  Checkbox,
  Rate,
  Progress,
  Image,
  Card,
  Form,
  Spin,
  Input,
  Select,
  InputNumber,
  TimePicker,
} from "antd";
import { useNavigate,useParams } from "react-router";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { LESSON,UPLOADS_URL, USERS } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { Get } from "../../config/api/get";
import { CHAT } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { SUBJECTS, CONTENT_TYPE } from "../../config/constants/index";
import swal from "sweetalert";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
//icons
import { FaLongArrowAltLeft } from "react-icons/fa";
import { TbCameraPlus } from "react-icons/tb";
import { BsFiles } from "react-icons/bs";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";
//icons
import { FaArrowRight ,FaArrowLeft } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";


function LessonDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lesson,setLesson]= useState({});
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);


  useEffect(()=>{
    getLessonDetails();
  },[])


  const handleUpdate = () =>{
    Post(LESSON.updateLesson+id,lesson,token)
    .then((response) => {
      setLoading(false);
      if (response?.data?.status) { 
        swal("Success!", "Lesson Updated Successfully", "success");
        getLessonDetails()  
        setLoading(false);
      } else {
        swal("Oops!", response.data.message, "error");
      }
    })
    .catch((e) => {
      setLoading(false);
    });
  }

  const startLesson = () =>{
    if(!lesson.zoomUrl){
      swal("Error!", "Please set Url First", "error");
      return;
    }
    Post(LESSON.updateLesson+id,{status:"LIVE"},token)
    .then((response) => {
      setLoading(false);
      if (response?.data?.status) { 
        swal("Success!", "Lesson Started Successfully", "success");
        navigate("/dashboard")

        getLessonDetails()  
        setLoading(false);
      } else {
        swal("Oops!", response.data.message, "error");
      }
    })
    .catch((e) => {
      setLoading(false);
    });
  }


  const getLessonDetails = async () =>{

    try {
      const response = await Get(LESSON.getLessonById + id,token);

      console.log("response",response)
      if (response?.status) {
        setLesson(response?.data?.lesson);
      } else {
        swal("Error", response?.data?.message, "error");
        
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }


  const handleChange = (item,value) =>{
    let _lesson = {...lesson}
    _lesson[item] = value;
    setLesson(_lesson)
  }


  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> Lesson {lesson?.lessonId}</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                minHeight:500
              }}
            >
              {loading && <Row style={{minHeight:"50vh"}} className="flex">
              <Spin size="large" />
                </Row>}
             {!loading && 
             <Row>
                <Col xs={24}>
                  <Row>
                    <Col xs={24} sm={12}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 20,
                        }}
                      >
                       Invite Link
                      </Typography.Title>
                      <Input
                        size="large"
                        placeholder="Add invite Link"
                        className="signupFormInput"
                        value={lesson?.zoomUrl}
                        onChange={(e)=> handleChange("zoomUrl",e.target.value)}
                      />
                    
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={12}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 20,
                        }}
                      >
                       Meeting Id
                      </Typography.Title>
                      <Input
                        size="large"
                        placeholder="Add Meeting Id"
                        className="signupFormInput"
                        value={lesson?.meetingId}
                        onChange={(e)=> handleChange("meetingId",e.target.value)}
                      />
                    
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={12}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 20,
                        }}
                      >
                       Passcode
                      </Typography.Title>
                      <Input.Password
                        size="large"
                        placeholder="Add passcode"
                        className="signupFormInput"
                        value={lesson?.passcode}
                        onChange={(e)=> handleChange("passcode",e.target.value)}
                      />
                    
                    </Col>
                  </Row>


                  <Row style={{ marginTop: 30 }}><Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton"
                        onClick={()=> handleUpdate()}
                      >
                        
                       Add Url
                      </Button>
                      &emsp;
                      <Button
                           className="fontFamily1"
                           style={{
                             marginTop: "0px",
                             padding: "10px 30px",
                             cursor: "pointer",
                             color: "black",
                             height: "auto",
                             border: "1px solid #203657",
                             fontWeight: "bold",
                           }}
                           ghost
                           size="large"
                           onClick={()=> startLesson()}
                         >
                           Start Lesson
                         </Button>
                 </Row>

                </Col>
              </Row>}
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default LessonDetail;
